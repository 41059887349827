<template>
  <div class="home">
    <!-- <div @click="toabouit">quewgioewiogj4ijgioj</div> -->
    <div class="ba-01-top">
      <!-- <img class="bg-01" src="../assets/1.png" alt=""> -->
      <!-- <img class="bg-02" src="../assets/2.png" alt=""> -->
    </div>
    <div class="bg-02"></div>
    <div class="bg-03"></div>
    <div class="bg-04"></div>
    <div class="bg-05"></div>
    <div class="bg-06"></div>
    <div class="bg-07"></div>
    <div class="bg-08"></div>
    <div class="bottombei">
      <span style="margin-left: 20px">
        <a href="https://beian.miit.gov.cn/">沪ICP备2021023371号-1</a></span>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "HomeView",
  data() {
    return {};
  },
  methods: {
    toabouit() {
      this.$router.push("/about");
    },
  },
  created() {},
};
</script>

<style scoped lang='less'>
* {
  margin: 0;
  padding: 0;
}
.home {
  .ba-01-top {
    height: 100%;
    width: 100%;

    height: 90vh;
    background-repeat: no-repeat;

    background-image: url(../assets/1.png);
    background-position: center;
    background-size: cover;
    .bg-01 {
    }
  }
  .bg-02 {
    height: 10vh;
    width: 100%;
    background-repeat: no-repeat;
    background-image: url(../assets/2.png);
    background-position: center;
    background-size: cover;
    z-index: 999;
  }
  .bg-03 {
    height: 100%;
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-image: url(../assets/3.png);
    background-position: center;
    background-size: cover;
  }
  .bg-04 {
    height: 100%;
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-image: url(../assets/4.png);
    background-position: center;
    background-size: cover;
  }
  .bg-05 {
    height: 100%;
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-image: url(../assets/5.png);
    background-position: center;
    background-size: cover;
  }
  .bg-06 {
    height: 100%;
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-image: url(../assets/6.png);
    background-position: center;
    background-size: cover;
  }
  .bg-07 {
    height: 100%;
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-image: url(../assets/7.png);
    background-position: center;
    background-size: cover;
  }
  .bg-08 {
    height: 100%;
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-image: url(../assets/8.png);
    background-position: center;
    background-size: cover;
  }
  .bottombei {
    width: 100%;
    height: 38px;
    background-color: white;
    margin-top: -38px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
