import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { loadStyle, downBlobFile } from "@/util/util";
Vue.prototype.downBlobFile = downBlobFile;

Vue.config.productionTip = false

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import * as urls from "@/config/env";
// 加载相关url地址
Object.keys(urls).forEach(key => {
  Vue.prototype[key] = urls[key];
});

// import { loadStyle } from './util/util'
import { iconfontUrl, iconfontVersion } from "@/config/env";
// 动态加载阿里云字体库

iconfontVersion.forEach(ele => {
  console.log(iconfontUrl.replace("$key", ele));
  loadStyle(iconfontUrl.replace("$key", ele));
});


//百度地图
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: 'PQoRDmLggt0UFy8QGbM0k2O96gdKMj1h'
})


// import Vue from 'vue';
// import { Toast } from 'vant';

// Vue.use(Toast);

// import '../public/ueditor/ueditor.config'
// import '../public/ueditor/ueditor.all.min'
// import '../public/ueditor/lang/zh-cn/zh-cn'
// import '../public/ueditor/ueditor.parse.min'

const bus = new Vue()
// 把bus挂载到了Vue的原型上, 保证所有的组件都能通过 this.$bus访问到事件总线
Vue.prototype.$bus = bus

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
