<template>
  <div class="content">
    <div class="firstbox">
      <div class="starfather">
        <div class="">
          <img class="start" src="../assets/start.png" mode="" />
        </div>
        <div class="texting">
          <div class="textself">注册每月领150元快餐券</div>
          <div class="backline"></div>
        </div>
        <div class="">
          <img class="start" src="../assets/start.png" mode="" />
        </div>
      </div>
      <div class="">
        <input
          class="firinput"
          type="text"
          value=""
          placeholder="请输入手机号"
          v-model="mobile"
          maxlength="11"
        />
        <!-- oninput="
                  if (this.value > 12) this.value = this.value.slice(0, 11);
                " -->
      </div>
      <div class="infather">
        <input
          class="secinput"
          type="text"
          value=""
          placeholder="请输入短信验证码"
          v-model="code"
          maxlength="6"
        />
        <div class="sendcode" @click="togetcode">获取验证码</div>
      </div>
      <img
        v-if="changered == true"
        class="rightnow"
        src="../assets/rightnow.png"
        mode=""
        @click="toreigister"
      />
      <img v-else class="rightnow" src="../assets/rightnowred.png" mode="" />
    </div>
    <div class="secondbox">
      <div class="suremake">
        <div class="fasting">利行保障</div>
        <div class="">
          <img
            class="shield"
            style="height: 80px"
            src="../assets/icon1.png"
            mode=""
          />
        </div>
      </div>
      <div
        class="bottomwhite"
        style="position: relative; z-index: 2; margin-top: -11px"
      >
        <div class="words">
          多款车型供您选，专业人员陪同验车，确保安全温馨体贴随用随租；
        </div>
        <div class="words">
          无需支付其他各种费用节省管理及运营成本，利行为您贴身定制租车服务，专业高效式服务；
        </div>
      </div>
    </div>
    <div class="secondbox">
      <div class="suremake">
        <div class="fasting">轻松租车</div>
        <div class="">
          <img
            class="shield"
            style="height: 80px"
            src="../assets/clock.png"
            mode=""
          />
        </div>
      </div>
      <div
        class="bottomwhite"
        style="position: relative; z-index: 2; margin-top: -12px"
      >
        <div class="words">
          驾照、保险、交规3分钟上手；驾驶习惯，停车指引加油指南；定期更换新车，
          安全可靠。
        </div>
      </div>
    </div>
    <div class="secondbox" style="z-index: 0">
      <div class="suremake">
        <div class="fasting">多重福利</div>
        <div class="">
          <img
            style="width: 88px; height: 88px"
            class="shield"
            src="../assets/money.png"
            mode=""
          />
        </div>
      </div>
      <div class="bottomwhite bott0omwhite">
        <div class="words nathe">
          <span> 下载利行司机版APP，认证您的驾驶证，平台</span>
          <span class="changered">免费赠送3张快餐券 </span>；
        </div>
        <div class="words nathe">
          通过利行平台线上签约，合约期间<span class="changered"
            >再免费赠送6张快餐券</span
          >，签约用户<span class="changered">购买餐券享受6.8折购券再减2元</span
          >。
        </div>
      </div>
    </div>
    <div class="download" @click="moreting">更多福利，下载利行 ></div>
  </div>
</template>

<script>
import { getcode, getregid, getyan } from "../util/run.js";
export default {
  data() {
    return {
      mobile: "",
      code: "",
      changered: false,
      needpram: {
        mobile: "",
        grant_type: "app",
        fdFlag: null,

        redflg: null,
      },
    };
  },
  mounted() {
    // document.documentElement.style.overflow = "hidden";
    document.title = "下载利行";
  },
  methods: {
    // lastmath(e) {
    //   console.log(e);
    //   var axking = {
    //     phone: this.mobile,
    //   };
    //   if (this.mobile.length == 11) {
    //     getyan(axking).then((res) => {
    //       console.log(res);
    //       // this.$message(res.data.msg);
    //       if (res.data.data === false) {
    //         this.$message("手机号已经注册");
    //       } else {
    //         // this.$message(res.data.msg);
    //       }
    //     });
    //   }
    // },
    moreting() {
      var u = navigator.userAgent;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android终端或者uc浏览器
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (!isAndroid) {
        window.location.href =
          "https://apps.apple.com/cn/app/%E5%88%A9%E8%A1%8C/id1602004635";
      } else {
        window.location.href =
          "https://webcdn.m.qq.com/webapp/homepage/index.html#/appDetail?apkName=com.huiguan.homeofcar&info=6ABE4F5F0DB8B9C16E13AE49400B0BDE";
      }
      console.log(11111111);
    },
    togetcode() {
      clearTimeout(this.fdFlag);
      this.fdFlag = setTimeout(() => {
        this.anotherding();
        this.fdFlag = null;
      }, 800);
    },
    anotherding() {
      var axking = {
        phone: this.mobile,
      };
      getyan(axking).then((res) => {
        console.log(res);
        // this.$message(res.data.msg);
        this.changered = res.data.data;
        if (res.data.data === false) {
          this.$message("手机号已经注册");
        } else {
          var telText = /^1\d{10}$/;
          if (this.mobile == "") {
            this.$message("请填写手机号");
          } else if (!telText.test(this.mobile)) {
            this.$message("请填写正确的手机号");
          } else {
            var needpram = {
              phone: this.mobile,
              type: 1,
            };
            getcode(needpram).then((res) => {
              console.log(res);
              if (res.data.code === 0) {
                this.$message.success(res.data.msg);
              } else {
                this.$message(res.data.msg);
              }
            });
          }
        }
      });
    },

    toreigister() {
      clearTimeout(this.redflg);
      this.redflg = setTimeout(() => {
        this.register();
        this.redflg = null;
      }, 300);
    },
    register() {
      // this.$refs.uToast.show({
      //   message: "34287732887932789",
      // });
      var telText = /^1\d{10}$/;
      if (this.mobile == "") {
        this.$message({ message: "请填写手机号", duration: 1000 });
      } else if (!telText.test(this.mobile)) {
        this.$message("请填写正确的手机号");
      } else if (this.code == "") {
        this.$message("请填写验证码");
      } else {
        var needpram = {
          mobile: this.mobile,
          grant_type: "app",
          code: this.code,
          // type: -1,
        };
        getregid(needpram).then((res) => {
          console.log(res);
          if (res.data.code == 1) {
            this.$message(res.data.msg);
          } else {
            this.code = "";
            this.mobile = "";
            // this.$message(res.data.msg);
            this.$message("注册成功");
          }
          // this.$message(res.data.msg);
          // if (res.data.code === 0) {
          //   this.$message.success(res.data.msg);
          // } else {
          //   this.$message(res.data.msg);
          // }
        });
      }
    },
  },
};
</script>

<style>
html,
body {
  /* height: 100vh; */
  /* overflow-x: hidden;
  overflow-y: hidden; */
  /* overflow: hidden; */
  /* position: fixed;
  top: 0px; */
  /* left: 0px; */
}
.content {
  /* overflow-y: auto; */
  background-repeat: no-repeat;
  background-image: url(../assets/back.png);
  /* background-position: center; */
  background-size: cover;
  box-sizing: border-box;
  /* height: 100vh; */
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 56px;
  /* overflow: hidden; */

  /* position: fixed;
  top: 0px;
  bottom: 0px; */
  /* left: 0; */
}

.firstbox {
  width: 355px;
  height: 300px;
  background: #fdf1e5;
  box-shadow: inset 0px 6px 14px 0px #fff1d8;
  border-radius: 8px;
  margin: 0 auto;
  margin-top: 375px;
}

.starfather {
  width: 300px;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 23px;
}

.start {
  width: 14px;
  height: 14px;
}

.texting {
  margin: 0 auto;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.textself {
  /* width: 212px; */
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #722f08;
  line-height: 28px;
  display: flex;
  justify-content: center;
}

.backline {
  width: 230px;
  height: 8px;
  margin-top: -7px;
  background: rgba(255, 117, 46, 0.1);
}

.firinput {
  width: 325px;
  height: 50px;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  margin: 0 auto;
  text-indent: 1em;
  outline: none;
  border: none;
  font-size: 14px;
}

.infather {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 12px;
}

.secinput {
  width: 200px;
  height: 50px;
  background: #ffffff;
  border-radius: 4px;
  margin-right: 11px;
  text-indent: 1em;
  outline: none;
  border: none;
}

.sendcode {
  width: 114px;
  height: 50px;
  background: linear-gradient(180deg, #f8ee7a 0%, #fea92d 100%);

  border-radius: 4px;

  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #722f08;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rightnow {
  width: 325px;
  height: 65px;
  margin: 0 auto;
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.secondbox {
  width: 355px;
  /* height: 167px; */
  background: #fdf1e5;
  border-radius: 8px;
  margin: 0 auto;
  margin-top: 28px;
  padding-bottom: 10px;
}

.suremake {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.shield {
  width: 88px;
  height: 88px;
  display: flex;
  margin-top: -33px;
  margin-right: 10px;
}

.fasting {
  margin-left: 22px;

  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #722f08;
  line-height: 28px;
}

.bottomwhite {
  width: 335px;
  /* height: 114px; */
  background: #ffffff;
  margin: 0 auto;
  /* overflow: hidden; */
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.bott0omwhite {
  position: relative;
  margin-top: -20px;
  z-index: 2;
}

.words {
  margin: 0px 13px;
  margin-top: 12px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  /* line-height: 20px; */
  z-index: 2;
  text-align: left;
  display: flex;
  flex-direction: row;
}

.download {
  width: 312px;
  height: 60px;
  background: linear-gradient(180deg, #f8ee7a 0%, #fea92d 100%);
  border-radius: 33px;

  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #722f08;
  line-height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 46px;
}
.changered {
  color: #ff4a42;
}
.nathe {
  display: inline;
}
input::-webkit-input-placeholder {
  font-size: 14px;

  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
</style>

