import Vue from 'vue'
import axios from 'axios'

// import qs from 'qs'
// import { ElMessage } from "element-plus";
// import { useRouter, useRoute } from "vue-router";
// import router from "./../router/index"
const service = axios.create({
    // process.env.NODE_ENV === 'development' 来判断是否开发环境
    // easy-mock服务挂了，暂时不使用了
    // baseURL: 'https://api-carlease-m.lixingpt.com',
    // baseURL: '/api',
    // baseURL: 'https://zl.uat.lixingpt.com/',
    baseURL: 'https://api-carlease-m.lixingpt.com',
    
    // baseURL: 'http://42.192.77.180:6089',
    // baseURL: 'http://192.168.1.3:9999/',
    
    timeout: 5000
        // paramsSerializer: function(params) {//序列化请求参数，避免get请求参数出现&,空格等识别错误的问题
        //     return qs.stringify(params, {arrayFormat: 'brackets'})
        // }
});

service.interceptors.request.use(
    config => {
        if (!config.headers['Authorization']) {
            // config.headers["Authorization"] = localStorage.getItem("access_token"); // token
            config.headers['userType'] = '2',
                config.headers['type'] = '-1'

        }
        return config;
    },
    error => {
        return Promise.reject();
    }
);

// service.interceptors.response.use(
//     response => {
//         if (response.status === 200) {
//             if (response.data.code === 10005) {
//                 // localStorage.removeItem("ms_username");
//                 // localStorage.removeItem("access_token");
//                 // this.$router.push({ path: '/login'})
//                 router.push({ path: "/login" });
//                 return response.data;
//             } else {
//                 return response.data;
//             }

//         } else {
//             Promise.reject();
//         }
//     },
//     error => {
//         // ElMessage(error.response.data.msg)
//         return Promise.reject(error.response.data);
//     }
// );

export default service;