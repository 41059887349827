import request from './request.js';

export const getcode = (query) => {
    return request({
        url: '/driver/smsLog/sendSmsV2',
        method: 'post',
        data: query,
        headers: {
            type: -1,
            userType:2
        }
    });
};

export const getregid = (query) => {
    return request({
        url: '/auth/oauth/token',
        method: 'get',
        params: query,
        headers: {
            type: -1,
            userType: 2,
            Authorization:'Basic cGlnOnBpZw=='
        }
    });
};

export const getyan = (query) => {
    return request({
        url: '/webapp/driver/api/cduser/isRegistry',
        method: 'get',
        params: query,
        headers: {
            type: -1,
            userType: 2,
            Authorization:'Basic cGlnOnBpZw=='
        }
    });
};


