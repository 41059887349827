<template>
  <div class="hello">
    <el-upload
      action="http://42.192.77.180:6089/webapp/ad/app/tool/uplodFile"
      list-type="picture-card"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :on-success="handleAvatarSuccess"
      :limit="limit"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    limit: {
      default: "",
    },
  },
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      value: 333333333333,
    };
  },
  methods: {
    handleAvatarSuccess(res, file) {
      // this.imageUrl = URL.createObjectURL(file.raw);
      // console.log(file);
      this.$emit("eventbus", this.value);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
  },
};
</script>

</style>
